import { DBFactory } from "classes/database/db_factory";
import { Observable, combineLatest, map, switchMap } from "rxjs";
import {
  ApplicationCapabilityDefinition,
  ApplicationCapabilityDefinitions,
} from "./application-capability-definition.model";
import { Capabilities, Capability } from "./capability.model";
import {
  CapabilityPackage,
  CapabilityPackages,
} from "./capability-package.model";
import { CapabilityType } from "~/types/enums/CapabilityType.enum";
import {
  ApplicationCapabilityCategories,
  ApplicationCapabilityCategory,
} from "./application-capability-category.model";
import { UserPackage, UserPackages } from "./user-package.model";
import { PackageType } from "~/types/enums/PackageType.enum";
import { SubscriptionInfo } from "./subscription-info.model";
import { UserPackageVersion } from "./user-package-version.model";
import { UserCapabilities } from "./user-capabilities.model";

export class CapabilitiesService {
  static streamApplicationCapabilityDefinitions(): Observable<ApplicationCapabilityDefinitions> {
    const db = DBFactory.createDatabase();

    return db
      .streamList({
        path: "applicationCapabilityDefinitions",
        collection: "applicationCapabilityDefinitions",
      })
      .pipe(
        map((data: any) => {
          return data.map((map: any) => {
            return ApplicationCapabilityDefinition.fromMap(map);
          });
        })
      );
  }

  static streamApplicationCapabilityCategories(): Observable<ApplicationCapabilityCategories> {
    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          path: "applicationCapabilityCategories",
          collection: "applicationCapabilityCategories",
        },
        [
          {
            type: "orderBy",
            field: "displayOrder",
            direction: "asc",
          },
        ]
      )
      .pipe(
        map((data) => {
          return data.map((map: any) => {
            return ApplicationCapabilityCategory.fromMap(map);
          });
        })
      );
  }

  static async getDefaultApplicationVersion(): Promise<string> {
    const definition = await this.getDefaultApplicationCapabilityDefinition(
      false
    );
    return definition.version;
  }

  static async getDefaultApplicationCapabilityDefinition(
    includeCapabilities: boolean = true
  ): Promise<ApplicationCapabilityDefinition> {
    const db = DBFactory.createDatabase();

    const data = await db.list(
      {
        path: "applicationCapabilityDefinitions",
        collection: "applicationCapabilityDefinitions",
      },
      [
        {
          type: "where",
          field: "isPrimary",
          operator: "==",
          value: true,
        },
      ]
    );

    const definitionData = data[0];

    if (includeCapabilities) {
      definitionData.capabilities = (
        await this.getDefinitionCapabilities(definitionData.version)
      ).map((capability) => capability.toMap());
    }

    return ApplicationCapabilityDefinition.fromMap(definitionData);
  }

  static streamDefaultApplicationCapabilityDefinition() {
    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          path: "applicationCapabilityDefinitions",
          collection: "applicationCapabilityDefinitions",
        },
        [
          {
            type: "where",
            field: "isPrimary",
            operator: "==",
            value: true,
          },
        ]
      )
      .pipe(
        switchMap((applicationDefinition: ModelDatabaseData[]) => {
          return this.streamApplicationCapabilityDefinition(
            applicationDefinition[0].version
          );
        })
      );
  }

  static async getApplicationCapabilityDefinition(
    version: string
  ): Promise<ApplicationCapabilityDefinition> {
    const db = DBFactory.createDatabase();

    const data = await db.get({
      path: `applicationCapabilityDefinitions/${version}`,
      collection: "applicationCapabilityDefinitions",
    });

    data.capabilities = (await this.getDefinitionCapabilities(version)).map(
      (capability) => capability.toMap()
    );

    return ApplicationCapabilityDefinition.fromMap(data);
  }

  static streamApplicationCapabilityDefinition(version: string) {
    const db = DBFactory.createDatabase();

    return combineLatest([
      db.streamRecord({
        path: `applicationCapabilityDefinitions/${version}`,
        collection: "applicationCapabilityDefinitions",
      }),
      db
        .streamList({
          path: `applicationCapabilityDefinitions/${version}/capabilities`,
          collection: `applicationCapabilityDefinitions/${version}/capabilities`,
        })
        .pipe(
          map((data: any) => {
            return data;
          })
        ),
    ]).pipe(
      map(([applicationCapabilityDefinitionData, capabilities]) => {
        applicationCapabilityDefinitionData!.capabilities = capabilities;
        return ApplicationCapabilityDefinition.fromMap(
          applicationCapabilityDefinitionData
        );
      })
    );
  }

  static async getDefinitionCapabilities(
    version: string
  ): Promise<Capabilities> {
    const db = DBFactory.createDatabase();
    const data = await db.list({
      path: `applicationCapabilityDefinitions/${version}/capabilities`,
      collection: `applicationCapabilityDefinitions/${version}/capabilities`,
    });

    return data.map((map: any) => {
      return Capability.fromMap(map);
    });
  }

  static async getUserCapabilitiesVersion(userId: string): Promise<string> {
    try {
      const db = DBFactory.createDatabase();
      const data = await db.list({
        path: `users/${userId}/capabilities`,
        collection: `users/${userId}/capabilities`,
      });

      // Sort versions by version which is a string
      data.sort((a, b) => {
        return a.version.localeCompare(b.version);
      });

      return data[data.length - 1].version;
    } catch (error) {
      return await this.getDefaultApplicationVersion();
    }
  }

  static async getUserPackageVersion(
    userId: string
  ): Promise<UserPackageVersion> {
    try {
      const db = DBFactory.createDatabase();
      const data = await db.list({
        path: `users/${userId}/capabilities`,
        collection: `users/${userId}/capabilities`,
      });

      // Sort versions by version which is a string
      data.sort((a, b) => {
        return a.version.localeCompare(b.version);
      });

      var newData = data[data.length - 1];

      if (newData == undefined) {
        throw new Error("No user package version found");
      }

      return UserPackageVersion.fromMap(newData);
    } catch (error) {
      return new UserPackageVersion({
        userId,
        version: await this.getDefaultApplicationVersion(),
      });
    }
  }

  static async getUserPackages(
    userId: string,
    version?: string
  ): Promise<UserPackages> {
    try {
      const db = DBFactory.createDatabase();

      const activeVersion =
        version ?? (await this.getUserCapabilitiesVersion(userId));

      const userPackagesData = await db.list({
        path: `users/${userId}/capabilities/${activeVersion}/packages`,
        collection: `users/${userId}/capabilities/${activeVersion}/packages`,
      });

      return userPackagesData.map((map: any) => {
        return UserPackage.fromMap(map);
      });
    } catch (error) {
      return [];
    }
  }

  static async getActiveUserPackages(
    userId: string,
    version: string
  ): Promise<UserPackages> {
    const allPackages = await this.getUserPackages(userId, version);

    return allPackages.filter((userPackage) => {
      return this.isPackageActive(userPackage);
    });
  }

  static extractActiveUserPackages(
    userId: string,
    userCapabilityPackages: UserPackages
  ) {
    let combinedCapabilities = {};

    for (var userCapability of userCapabilityPackages) {
      combinedCapabilities = {
        ...combinedCapabilities,
        ...userCapability.capabilities,
      };
    }

    return new UserPackage({
      id: UserPackage.combinedIdentifier,
      packageId: UserPackage.combinedIdentifier,
      userId,
      version: "",
      lastUpdatedTimestamp: new Date().getTime(),
      capabilities: combinedCapabilities ?? {},
      quantity: 0,
      displayOrder: 0,
      type: PackageType.combined,
      category: "",
      name: "",
      useCategoryAsId: false,
      description: "",
    });
  }

  static streamUserApplicationDefinition(
    userId: string
  ): Observable<ApplicationCapabilityDefinition> {
    const db = DBFactory.createDatabase();

    return db
      .streamList({
        path: `users/${userId}/capabilities`,
        collection: `users/${userId}/capabilities`,
      })
      .pipe(
        switchMap((data) => {
          if (data.length == 0) {
            return this.streamDefaultApplicationCapabilityDefinition();
          }

          data.sort((a, b) => {
            return a.version.localeCompare(b.version);
          });

          const activeVersion = data[data.length - 1].version;
          return this.streamApplicationCapabilityDefinition(activeVersion);
        })
      );
  }

  static isPackageActive(userPackage: UserPackage) {
    if (userPackage.subscriptionInfo == undefined) return true;

    return (
      userPackage.subscriptionInfo.subscriptionExpiryTimestamp >
      new Date().getTime()
    );
  }

  static streamApplicationDefinitionAndActiveUserPackages(
    userId: string
  ): Observable<{
    applicationDefinition: ApplicationCapabilityDefinition;
    userPackages: UserPackages;
  }> {
    const db = DBFactory.createDatabase();

    return this.streamUserApplicationDefinition(userId).pipe(
      switchMap((applicationCapabilitiesDefinition) => {
        // sort by data.version which is a string
        const activeVersion = applicationCapabilitiesDefinition.version;

        return db
          .streamList({
            path: `users/${userId}/capabilities/${activeVersion}/packages`,
            collection: `users/${userId}/capabilities/${activeVersion}/packages`,
          })
          .pipe(
            map((data) => {
              var packages = data
                .map((map: any) => {
                  return UserPackage.fromMap(map);
                })
                .filter((userPackage) => {
                  return this.isPackageActive(userPackage);
                });

              return {
                applicationDefinition: applicationCapabilitiesDefinition,
                userPackages: packages,
              };
            })
          );
      })
    );
  }

  static async getUserPackage(
    userId: string,
    version: string,
    packageIdentifier: string
  ): Promise<UserPackage> {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      path: `users/${userId}/capabilities/${version}/packages/${packageIdentifier}`,
      collection: `users/${userId}/capabilities/${version}/packages`,
    });

    return UserPackage.fromMap(data);
  }

  static streamVersionPackages(
    version: string
  ): Observable<CapabilityPackages> {
    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          collection: `applicationCapabilityDefinitions/${version}/packages`,
          path: `applicationCapabilityDefinitions/${version}/packages`,
        },
        [
          {
            type: "orderBy",
            field: "name",
            direction: "asc",
          },
        ]
      )
      .pipe(
        map((data) => {
          return data.map((map: any) => {
            return CapabilityPackage.fromMap(map);
          });
        })
      );
  }

  static async getActiveCapabilityPackages(): Promise<CapabilityPackages> {
    const version = await this.getDefaultApplicationVersion();
    return await this.getVersionPackages(version);
  }

  static async getVersionPackages(
    version: string
  ): Promise<CapabilityPackages> {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        collection: `applicationCapabilityDefinitions/${version}/packages`,
        path: `applicationCapabilityDefinitions/${version}/packages`,
      },
      [
        {
          type: "orderBy",
          field: "name",
          direction: "asc",
        },
      ]
    );

    return data.map((map: any) => {
      return CapabilityPackage.fromMap(map);
    });
  }

  static async getVersionPackage(
    version: string,
    packageId: string
  ): Promise<CapabilityPackage> {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      path: `applicationCapabilityDefinitions/${version}/packages/${packageId}`,
      collection: `applicationCapabilityDefinitions/${version}/packages`,
    });

    return CapabilityPackage.fromMap(data);
  }

  static async applyPackageToUser({
    packageId,
    userId,
    quantity = 1,
    organizationId,
    subscriptionInfo,
  }: {
    packageId: string;
    userId: string;
    quantity?: number;
    organizationId?: string;
    subscriptionInfo?: SubscriptionInfo;
  }) {
    const db = DBFactory.createDatabase();
    const allPackagesData = await db.list({
      path: `applicationCapabilityDefinitions`,
      collection: `applicationCapabilityDefinitions`,
      collectionGroup: "packages",
    });

    const allPackages = allPackagesData.map((map: any) => {
      return CapabilityPackage.fromMap(map);
    });

    const packageToApply = allPackages.find((packageInstance) => {
      return packageInstance.id === packageId;
    });

    if (!packageToApply) {
      throw new Error("Package not found");
    }

    if (quantity > 0) {
      await UserPackage.applyPackageToUser({
        userId,
        capabilityPackage: packageToApply,
        quantity: packageToApply.allowQuantity ? quantity : 1,
        organizationId,
        subscriptionInfo,
      });
    } else {
      const userCapabilities = await UserCapabilities.initialize(userId);

      if (!userCapabilities.canRemovePackage(packageId)) {
        throw new Error("Cannot remove package");
      }

      await UserPackage.removePackageFromUser(userId, packageToApply);
    }
  }
}
